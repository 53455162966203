import React, { useState } from 'react';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import { deviceTypeList } from '../../utils/deviceTypeList';
import { Info } from '../../assets/Icons';
import ExplanationOverlay from '../ExplanationOverlay';
import i18next from 'i18next';

export default function DeviceTypeSelection(props: {
	deviceType: string | null;
	setDeviceType: React.Dispatch<React.SetStateAction<string | null>>;
}) {
	const { t } = useTranslation();
	const [showDeviceHint, setShowDeviceHint] = useState<string | null>(null);
	const [showDeviceInfo, setShowDeviceInfo] = useState<boolean | null>(null);

	const clickHandler = (dT: string) => {
		setShowDeviceHint(showDeviceHint === dT ? null : dT);
		setShowDeviceInfo(true);
	};

	return (
		<>
			<SimpleBar
				className='device-type-selection'
				forceVisible='y'
				autoHide={false}
			>
				<div>
					{deviceTypeList.map((dT, index) => (
						<div className='device-type-tile'>
							<div
								className={`device-type-option${
									dT.img === props.deviceType
										? ' selected'
										: ''
								}`}
								onClick={() => {
									if (dT.img !== props.deviceType) {
										props.setDeviceType(dT.img);
									} else props.setDeviceType(null);
								}}
								key={`device-type-${dT}-${index}`}
							>
								<img
									alt={dT.name}
									src={'./device-types/' + dT.img + '.png'}
								/>
								<div className='image-sub-line'>
									<h4>{t('DeviceType' + dT.name)}</h4>
									<div
										className='info-circle'
										onClick={() => {
											clickHandler(dT.name);
										}}
									>
										{Info}
									</div>
								</div>
							</div>
						</div>
					))}
				</div>
			</SimpleBar>
			{showDeviceInfo && (
				<ExplanationOverlay
					button={() => setShowDeviceInfo(false)}
					title={i18next.t('DeviceType' + showDeviceHint)}
					text={i18next.t(showDeviceHint + 'Hint')}
					cancel={() => setShowDeviceInfo(false)}
				/>
			)}{' '}
		</>
	);
}
