export const deviceTypeList = [
	{ name: 'UmdaschV1', img: 'Umdasch_no-Soundbar' },
	{ name: 'UmdaschV2', img: 'Umdasch-2_no-Soundbar' },
	// { name: 'IsariaIAA', img: 'Isaria-IAA_2019' },
	// {
	// 	name: 'IsariaSoundbar',
	// 	img: 'Isaria-Prototype_Soundbar',
	// },
	// { name: 'Volke', img: 'Volke_Felgenspion' },
	// { name: 'EKiosk', img: 'eKiosk_Protoype' },
];
